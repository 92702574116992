import styled from 'styled-components'
import { Theme } from '../../constants/variables'

// const px2vw = (size, width = 1440) => `${(size / width) * 100}vw`;

/**
 * Styled h1 component.
 */
const Heading1 = styled.h1`
  font-family: ${Theme.font.primary};
  font-weight: ${Theme.fontWeight.lighter};
  font-size: ${Theme.fontSize.largest};
  line-height: ${Theme.lineHeight.largest};
  text-transform: uppercase;

  color: ${props => props.color ? props.color : Theme.color.light};
  margin-top: ${props => props.marginTop ? `${props.marginTop * 10}px` : `${Theme.spacing.one}`};
  margin-bottom: ${props => props.marginBottom ? `${props.marginBottom * 10}px` : `${Theme.spacing.one}`};
  
  @media (max-width: 767px) {
    font-size: ${Theme.fontSize.larger};
    line-height: ${Theme.lineHeight.larger};
    margin-bottom: ${props => props.marginBottom ? `${props.marginBottom * 5}px` : `${Theme.spacing.one}`};
  }
`

/**
 * Styled h2 component.
 */
const Heading2 = styled.h2`
  font-family: ${Theme.font.primary};
  font-weight: ${Theme.fontWeight.light};
  font-size: ${Theme.fontSize.largest};
  line-height: ${Theme.lineHeight.largest};
  text-transform: uppercase;

  color: ${props => props.color ? props.color : Theme.color.light};
  margin-top: ${props => props.marginTop ? `${props.marginTop * 10}px` : `${Theme.spacing.one}`};
  margin-bottom: ${props => props.marginBottom ? `${props.marginBottom * 10}px` : `${Theme.spacing.one}`};
  
  @media (max-width: 767px) {
    font-size: ${Theme.fontSize.larger};
    line-height: ${Theme.lineHeight.large};
    margin-top: ${props => props.marginTop ? `${props.marginTop * 5}px` : `${Theme.spacing.one}`};
    margin-bottom: ${props => props.marginBottom ? `${props.marginBottom * 5}px` : `${Theme.spacing.one}`};
  }
`

/**
 * Styled h3 component.
 */
const Heading3 = styled.h3`
  font-family: ${Theme.font.primary};
  font-weight: ${Theme.fontWeight.medium};
  font-size: ${Theme.fontSize.large};

  color: ${props => props.color ? props.color : Theme.color.light};
  margin-top: ${props => props.marginTop ? `${props.marginTop * 10}px` : `${Theme.spacing.one}`};
  margin-bottom: ${props => props.marginBottom ? `${props.marginBottom * 10}px` : `${Theme.spacing.one}`};

  @media (max-width: 767px) {
    font-size: ${Theme.fontSize.larger};
    line-height: ${Theme.lineHeight.large};
    margin-top: ${props => props.marginTop ? `${props.marginTop * 5}px` : `${Theme.spacing.one}`};
    margin-bottom: ${props => props.marginBottom ? `${props.marginBottom * 5}px` : `${Theme.spacing.one}`};
  }
`

/**
 * Styled h4 component.
 */
const Heading4 = styled.h4`
  font-weight: ${Theme.fontWeight.medium};
  text-transform: uppercase;
  font-size: ${Theme.fontSize.medium};
  font-family: ${Theme.font.primary};
  color: ${props => props.color ? props.color : Theme.color.light};
`

/**
 * Styled paragraph component.
 */
const ParagraphText = styled.p`
  font-size: ${Theme.fontSize.small};
  font-weight: ${Theme.fontWeight.light};
  color: ${props => props.color ? props.color : Theme.color.white};
`

const LinkText = styled.p`
  font-size: ${Theme.fontSize.small};
  font-weight: ${Theme.fontWeight.light};
  color: ${props => props.color ? props.color : Theme.color.white};
  &:hover {
    color: ${Theme.color.primary};
    -webkit-transition: color ${props => props.hover ? '0.25s' : '0.0s'} ease-in-out;
    -moz-transition: color ${props => props.hover ? '0.25s' : '0.0s'} ease-in-out;
    transition: color ${props => props.hover ? '0.25s' : '0.0s'} ease-in-out;
  }
`

/**
 * Styled span component.
 */
const ColorSpan = styled.span`
    color: ${Theme.color.primary};
    font-family: ${Theme.font.primary};
    text-transform: uppercase;
`

export {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  ParagraphText,
  LinkText,
  ColorSpan
} 