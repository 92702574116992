export const Color = {
  primary: '#2279E1',
  secondary: '#212D40',
  dark: '#161C27',
  light: '#C5D5E4',
  black: '#111',
  white: '#fff',
  white2: '#EFEFEF',
  action: '#A9FDA5',
  error: '#fe4a26',
  placeholder: 'gray',
  grayLight: '#c5c5c5',
  inputBackground: 'transparent'
}

export const Defaults = {
  sectionMinHeight: '400px'
}

export const Spacing = {
  half: '5px',
  one: '10px',
  two: '20px',
  three: '30px',
  four: '40px',
  eight: '80px',
  max: '100px',
}

export const Border = {
  onePrimary: `1px solid ${Color.primary}`,
  twoPrimary: `2px solid ${Color.primary}`,
  oneDark: `1px solid ${Color.dark}`,
  twoDark: `2px solid ${Color.dark}`,
  oneLight: `1px solid ${Color.placeholder}`,
  twoLight: `2px solid ${Color.placeholder}`,
  oneError: `1px solid ${Color.error}`,
  twoError: `2px solid ${Color.error}`
}

export const Font = {
  primary: 'Teko'
}

export const LineHeight = {
  largest: '60px',
  larger: '50px',
  large: '45px',
  medium: '30px',
  small: '22px',
  smaller: '18px',
  smallest: '16px',
}

export const FontSize = {
  largest: '80px',
  larger: '50px',
  large: '40px',
  medium: '30px',
  small: '22px',
  smaller: '18px',
  smallest: '16px',
}

export const FontWeight = {
  heavier: 700,
  heavy: 600,
  medium: 400,
  light: 300,
}

export const Theme = {
  color: Color,
  defaults: Defaults,
  spacing: Spacing,
  border: Border,
  font: Font,
  fontSize: FontSize,
  fontWeight: FontWeight,
  lineHeight: LineHeight
}